import { AboutQueryData, Image } from "../../types"

interface Input {
  data: AboutQueryData
}

type Output = { [key in keyof AboutQueryData]?: Image }

export function convertAboutQuery({ data }: Input): Output {
  return Object.entries(data).reduce<Output>((returnValue, entry) => {
    const [key, image] = entry

    if (image == null) {
      throw new Error("No file found")
    }

    return {
      ...returnValue,
      [key]: convertImageSharp(image?.childImageSharp!),
    }
  }, {})
}

type ImageSharp = NonNullable<
  NonNullable<AboutQueryData[keyof AboutQueryData]>["childImageSharp"]
>

function convertImageSharp({ fluid, original }: ImageSharp): Image {
  return {
    type: "Image",
    src: fluid!.src!,
    srcWebp: fluid!.srcWebp!,
    srcSet: fluid!.srcSet!,
    srcSetWebp: fluid!.srcSetWebp!,
    originalDimensions: {
      width: original!.width!,
      height: original!.height!,
    },
  }
}
