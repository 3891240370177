import React from "react"
import { graphql } from "gatsby"

import { SEO, Slideshow } from "../components"
import { isServer, convertAboutQuery } from "../utilities"

import { AboutQueryData, TextContent } from "../types"

interface Props {
  data: AboutQueryData
  location: Window["location"]
}

const About = ({ data, location }: Props) => {
  const {
    profileImage,
    developingImage,
    printingImage,
    printsImage,
  } = convertAboutQuery({
    data,
  })

  if (
    profileImage == null ||
    developingImage == null ||
    printingImage == null ||
    printsImage == null
  ) {
    throw new Error("Image not found")
  }

  const aboutContent: TextContent = {
    type: "TextContent",
    content: [
      { type: "BackLink", value: "/" },
      { type: "Title", value: "About" },
      {
        type: "Paragraph",
        value:
          "I'm a German-born, self taught film photographer with a passion for documenting my surroundings. I've picked up my first film camera in 2009 and am still trying to master the analog process. From hand processing film, scanning negatives, to printing high quality prints, I do everything at home.",
      },
      {
        type: "Paragraph",
        value:
          "This website is supposed to showcase some of my best work, with an occasional print sell here and there. If you are interested in updates, follow my Instagram account @realmaxhoffmann for more photos and updates.",
      },
      {
        type: "Paragraph",
        value: "Thank you for stopping by and supporting.",
      },
      {
        type: "Title",
        value: "Max Hoffmann",
      },
    ],
  }

  return (
    <>
      <SEO
        images={[profileImage]}
        description={aboutContent.content[2].value}
        meta={[
          {
            property: "og:type",
            content: "website",
          },
        ]}
        title="About"
        path={location.pathname}
      />
      {isServer() ? null : (
        <Slideshow
          items={[
            profileImage,
            aboutContent,
            developingImage,
            printsImage,
            printingImage,
          ]}
        />
      )}
    </>
  )
}

export default About

export const query = graphql`
  query About {
    profileImage: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxHeight: 2000) {
          srcSet
          srcSetWebp
          src
          srcWebp
        }
        original {
          height
          width
        }
      }
    }

    developingImage: file(relativePath: { eq: "F311_0012.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxHeight: 2000) {
          srcSet
          srcSetWebp
          src
          srcWebp
        }
        original {
          height
          width
        }
      }
    }

    printingImage: file(relativePath: { eq: "F311_0009.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxHeight: 2000) {
          srcSet
          srcSetWebp
          src
          srcWebp
        }
        original {
          height
          width
        }
      }
    }

    printsImage: file(relativePath: { eq: "F311_0020.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxHeight: 2000) {
          srcSet
          srcSetWebp
          src
          srcWebp
        }
        original {
          height
          width
        }
      }
    }
  }
`
